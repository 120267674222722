export const environment = {
  production: false,
  oktaConfig: {
    clientId: '0oa1ri9ypriosYYnb0h8',
    issuer: 'https://myappstest.halliburton.com/oauth2/aus1ria31i4D22n1a0h8',
    redirectUri: 'https://corememory-dev.ienergy.halliburton.com/login/callback',
    postLogoutRedirectUri: 'https://corememory-dev.ienergy.halliburton.com',
    scopes: ['openid', 'profile', 'email', 'address', 'phone', 'offline_access'],
    pkce: true,
    testing: {
      disableHttpsCheck: true,
    },
  },
};
