import {
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  Observable,
  Observer,
  of,
  Subject,
  switchMap,
} from 'rxjs';

import { CommonModule, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { EMAIL_REGEX } from '@core/constants/consts';
import {
  SAMPLE_TYPE_INFO_CLASS_MAP,
  SAMPLE_TYPE_INFO_KEY_MAP,
} from '@core/constants/sample.consts';
import { IdName } from '@core/models/id-name.model';
import { LocationTreeViewNode } from '@core/models/location-tree-view-node.model';
import { ValidationState } from '@core/models/validation-state.model';
import { Well } from '@core/models/well.model';
import { WellInformation } from '@core/models/well-information.model';
import { DefaultSampleType } from '@core/types/default-sample-type.type';
import { extractDepthValueFromGetById } from '@core/utils/common/extract-depth-value-from-get-by-id.util.';
import { throttleClick } from '@core/utils/decorators/throttle-click.decorator';
import { REQUIRED_FIELDS_ERROR_CODE, requiredFields } from '@core/utils/form/validator.config';
import { SampleType } from 'src/app/common/sample-check-in-menu/enums/sample-type.enum';
import { UnitInputField } from 'src/app/common/unit-input/enums/unit-input-field.enum';
import { LocationTreeService } from 'src/app/services/api/location-tree.service';
import { SampleService } from 'src/app/services/api/sample.service';
import { UnitAttributeService } from 'src/app/services/api/unit-attribute.service';
import { WellsService } from 'src/app/services/api/wells.service';
import { NotificationService } from 'src/app/services/notification.service';

import { ThrottleClickDirective } from '../../common/directives/throttle-click.directive';
import { SampleIdDisplayName } from '../../common/sample-table-selector-popup-field/models/sample-display-name.interface';
import { AttachedFileService } from '../../services/api/attached-file.service';
import { ExperimentDataService } from '../../services/api/experiment-data.service';
import { DataUrlBlobCodecService } from '../../services/data-url-blob-codec.service';
import { UserManagementService } from '../../services/user-management.service';
import { ExistingWorkOrdersModule } from '../work-order/existing-work-orders/existing-work-orders.component';
import { integerOnly } from '../work-order/form/integer-only.validator';
import {
  NewExperimentType,
  newExperimentTypeFields,
} from '../work-order/mocks/experiment-type-temporary.mock';
import { ExperimentField } from '../work-order/models/experiment-field.model';
import { WorkOrderImpl } from '../work-order/models/work-order.model';
import {
  WorkOrderFormShape,
  WorkOrderFormShapeImpl,
} from '../work-order/models/work-order-form-shape.model';
import { WorkOrderDisplayNamePipe } from '../work-order/pipes/work-order-display-name.pipe';
import { ExperimentTypeService } from '../work-order/services/experiment-type.service';
import { VendorNameService } from '../work-order/services/vendor-name.service';
import { WorkOrderService } from '../work-order/services/work-order.service';
import { WorkOrderFormComponent } from '../work-order/work-order-form/work-order-form.component';
import { AttachedFilesModule } from './attached-files-block/attached-files-block.component';
import { BaseSample } from './base-sample.class';
import { ExperimentDataRenderTableModule } from './experiment-data-render-table/experiment-data-render-table.component';
import {
  allowedParentSampleErrorKey,
  allowedParentSampleValidator,
} from './form/allowed-parent-sample.validator';
import { depthInRange } from './form/depth-in-range.validator';
import {
  CoreSampleInformationFormField,
  CuttingsSampleInformationFormField,
  FluidSampleInformationFormField,
  PlugSampleInformationFormField,
  SampleCheckInFormField,
  ThinSectionSampleInformationFormField,
  UncategorizedSampleInformationFormField,
  WellInformationFormField,
} from './form/sample-check-in-form-field.enum';
import {
  ALL_FORM_LABELS,
  SAMPLE_CHECK_IN_FORM_LABEL,
  SAMPLE_TYPE_LABEL,
} from './form/sample-check-in-form-labels';
import { SampleFormGroup } from './form/sample-form-group.enum';
import { CoreInformation } from './models/core-information.model';
import { CrossUnitedSampleInfo } from './models/cross-united-sample-info.model';
import { CuttingsIntervalInformation } from './models/cuttings-interval-information.model';
import { ExperimentData } from './models/experiment-data.model';
import { FluidInformation } from './models/fluid-information.model';
import { PlugInformation } from './models/plug-information.model';
import { RelatedSamplesInfo } from './models/related-samples-info.model';
import { SampleAccessInfo } from './models/sample-access-info.model';
import { SampleCheckInForm } from './models/sample-check-in-form.model';
import { SampleGetById } from './models/sample-get-by-id.model';
import { SampleSave } from './models/sample-save.model';
import { SampleSaveParticular } from './models/sample-save-particular.model';
import { ThinSectionInformation } from './models/thin-section-information.model';
import { UncategorizedInformation } from './models/uncategorized-information.model';
import { SampleAccessTableModule } from './sample-access-table/sample-access-table.component';
import { SampleActionsModule } from './sample-actions/sample-actions.component';
import {
  SampleGeneralFormComponent,
  SampleGeneralFormModule,
} from './sample-general-form/sample-general-form.component';
import { SampleHeaderModule } from './sample-header/sample-header.component';
import { SampleRelatedTableModule } from './sample-related-table/sample-related-table.component';
import {
  SampleTypedFormComponent,
  SampleTypedFormModule,
} from './sample-typed-form/sample-typed-form.component';
import {
  SampleWellFormComponent,
  SampleWellFormModule,
} from './sample-well-form/sample-well-form.component';

@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.scss'],
  providers: [WorkOrderDisplayNamePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleComponent extends BaseSample implements OnInit {
  @Input() isReadOnly = false;
  @Input() sample!: SampleGetById;

  sampleType!: DefaultSampleType;
  orientations = new Array<IdName>();
  sectionTypes = new Array<IdName>();
  stainings = new Array<IdName>();
  sectionOrigins = new Array<IdName>();
  preservationTypes = new Array<IdName>();
  restrictionTypes = new Array<IdName>();
  lengthUnits: IdName[] = [];
  vendorNames: IdName[] = [];
  experimentTypes: IdName[] = [];
  relatedSamples = new Array<RelatedSamplesInfo>();
  sampleAccess = new Array<SampleAccessInfo>();
  isOwner!: boolean;
  resetFormSubject = new Subject<void>();
  existingWorkOrders: WorkOrderFormShape[] = [];
  experimentDataList: ExperimentData[] = [];
  sampleId?: string;
  sampleTypeLabel = SAMPLE_TYPE_LABEL;

  private noRestrictionValue!: IdName;
  private parentSampleId?: string;
  private isFormInitialized$ = new Subject<void>();
  private currentUser: string | undefined;
  private parentSample: { value: SampleIdDisplayName | null } = { value: null };

  @ViewChild(SampleGeneralFormComponent)
  private sampleGeneralFormRef!: SampleGeneralFormComponent;

  @ViewChild(SampleTypedFormComponent)
  private sampleTypedFormRef!: SampleTypedFormComponent;

  @ViewChild(SampleWellFormComponent)
  private wellFormRef!: SampleWellFormComponent;

  get typedFormGroup(): UntypedFormGroup {
    return this.sampleFormGroup.get(SampleFormGroup.TYPED) as UntypedFormGroup;
  }

  get wellFormGroup(): UntypedFormGroup {
    return this.sampleFormGroup.get(SampleFormGroup.WELL) as UntypedFormGroup;
  }

  get childSampleTypeOptions(): SampleType[] {
    return this.sampleType === SampleType.UNCATEGORIZED
      ? [SampleType.UNCATEGORIZED]
      : [
          SampleType.PLUG,
          SampleType.CORE,
          SampleType.CUTTINGS,
          SampleType.FLUID,
          SampleType.UNCATEGORIZED,
          SampleType.THINSECTION,
        ];
  }

  private get parentSampleFormControl(): UntypedFormControl {
    return this.generalFormGroup.get(SampleCheckInFormField.PARENT_SAMPLE) as UntypedFormControl;
  }

  private get wellIdFormControl(): UntypedFormControl {
    return this.wellFormGroup.get(WellInformationFormField.WELL_ID) as UntypedFormControl;
  }

  private get routerAppPrefix(): string {
    return `/${/(?<=\/)(.*?)(?=\/)/.exec(this.router.url)?.[0]}`;
  }

  constructor(
    protected override location: Location,
    private sampleService: SampleService,
    private attachedFileService: AttachedFileService,
    private domSanitizer: DomSanitizer,
    private wellsService: WellsService,
    private locationTreeService: LocationTreeService,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    private unitAttributeService: UnitAttributeService,
    private userManagementService: UserManagementService,
    private workOrderService: WorkOrderService,
    private vendorNameService: VendorNameService,
    private experimentTypeService: ExperimentTypeService,
    private dataUrlBlobCodecService: DataUrlBlobCodecService,
    private experimentDataService: ExperimentDataService,
  ) {
    super(location);
  }

  ngOnInit() {
    this.currentUser = this.userManagementService.getUserInfo()?.email;

    if (this.isReadOnly) {
      this.sampleId = this.sample.id;
      this.sampleType = this.sample.type as DefaultSampleType;
      this.initComponentData();

      this.isFormInitialized$.pipe(this.takeUntilDestroyed()).subscribe(() => {
        this.sampleFormGroup.disable();
      });
    } else {
      this.handleRouteParams();
      this.updateRouteParams();
    }
  }

  submitForm(sampleActionsComponent: { savingFinished(): void }): void {
    if (!this.sampleFormGroup.invalid) {
      const sample = this.mapToSampleSaveParticularModel(this.sampleFormGroup.getRawValue());
      const sampleImage = this.picture.src
        ? this.dataUrlBlobCodecService.dataUrlToFile(this.picture.src)
        : null;
      const sampleFullName = this.getDefaultNamingFormula(sample);

      if (this.isEdit) {
        this.updateSample(sample, sampleImage, sampleFullName, sampleActionsComponent);
      } else {
        this.checkInSample(sample, sampleImage, sampleFullName, sampleActionsComponent);
      }
    } else {
      this.highlightErrorFields();

      const validationErrorMessage = this.getFirstMessage();
      this.notificationService.notifyError(validationErrorMessage);
      sampleActionsComponent.savingFinished();
    }
  }

  navigateToSampleCheckInPage(type: SampleType): void {
    this.router.navigate([`${this.routerAppPrefix}/sample-check-in/${type}`], {
      queryParams: { parentSampleId: this.sampleId },
    });
  }

  protected override initComponentData(): void {
    super.initComponentData();

    this.initLists()
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        [
          this.sampleLocations,
          this.orientations,
          this.preservationTypes,
          this.restrictionTypes,
          this.lengthUnits,
          this.vendorNames,
          this.experimentTypes,
          this.stainings,
          this.sectionOrigins,
          this.sectionTypes,
        ] = data;

        [this.noRestrictionValue] = this.restrictionTypes.filter(
          (type) => type.name === 'No restriction',
        );

        this.initForms(this.sampleType);
        this.getAdditionalInfo();
      });
  }

  protected override resetForm(): void {
    super.resetForm();
    this.parentSample.value = null;

    if (this.sampleGeneralFormRef) {
      this.sampleGeneralFormRef.isSamplePhotoChanged = false;
    }

    if (this.sampleFormGroup) {
      this.wellIdFormControl.enable({ emitEvent: false });
      this.resetFormSubject.next();
      this.generalFormGroup.controls[SampleCheckInFormField.OWNER].setValue(this.currentUser);
      this.generalFormGroup.controls[SampleCheckInFormField.RESTRICTION].setValue([
        this.noRestrictionValue.id,
      ]);
    }
  }

  protected override clearRouteParams(): void {
    this.sampleId = undefined;
    this.parentSampleId = undefined;
    this.router.navigate([]);
  }

  private getParenSampleForValidationPurpose(): void {
    this.sampleService
      .getById(this.parentSampleId!)
      .pipe(
        map((parentSample) => this.mapToSampleIdDisplayName(this.parentSampleId!, parentSample)),
        this.takeUntilDestroyed(),
      )
      .subscribe((parentSample) => {
        this.parentSample.value = parentSample;
      });
  }

  private mapToSampleIdDisplayName(id: string, sample: SampleGetById): SampleIdDisplayName {
    return {
      id,
      type: sample.type,
      depth: extractDepthValueFromGetById(sample),
      sampleName: sample.name,
      wellName: sample.wellInformation?.name ?? '',
    };
  }

  private highlightErrorFields(): void {
    this.typedFormGroup.updateValueAndValidity();
    this.generalFormGroup.updateValueAndValidity();
    this.sampleFormGroup.markAllAsTouched();
  }

  private mapToSampleSaveParticularModel(sample: SampleCheckInForm): SampleSaveParticular {
    const sampleGeneral = sample[SampleFormGroup.GENERAL];
    const sampleSave: SampleSave = {
      name: sampleGeneral.name,
      description: sampleGeneral.description || '',
      qrCodeId: sampleGeneral.qrCode,
      barcodeId: sampleGeneral.barcode,
      locationId: sampleGeneral.sampleLocation,
      parentSampleId: sampleGeneral.parentSample,
      sampleLimitationIds: sampleGeneral.restriction ? sampleGeneral.restriction : [],
      wellId: sample[SampleFormGroup.WELL].wellId,
      sampleImageId: this.sampleImageId,
      sampleImageOriginId: this.sampleImageOriginId,
      custodian: sampleGeneral.custodian,
      owner: sampleGeneral.owner,
    };

    const sampleSpecificInformation = this.getSampleSpecificInformation(sample);
    return {
      ...sampleSave,
      ...sampleSpecificInformation,
    } as SampleSaveParticular;
  }

  private getSampleSpecificInformation(
    sample: SampleCheckInForm,
  ): Record<
    string,
    | CoreInformation
    | PlugInformation
    | CuttingsIntervalInformation
    | FluidInformation
    | UncategorizedInformation
    | ThinSectionInformation
  > {
    const sampleTyped = sample[SampleFormGroup.TYPED];
    const crossUnitedSampleInfo: CrossUnitedSampleInfo = {
      depthTop: Number(sampleTyped?.depthTop?.[UnitInputField.FIELD_VALUE]) || 0,
      depthBottom: Number(sampleTyped?.depthBottom?.[UnitInputField.FIELD_VALUE]) || 0,
      depthUnitId:
        sampleTyped?.depth?.[UnitInputField.UNIT_ID] ||
        sampleTyped?.depthTop?.[UnitInputField.UNIT_ID] ||
        '',
      preservationId: sampleTyped.preservationType,
      depth: Number(sampleTyped?.depth?.[UnitInputField.FIELD_VALUE]) || 0,
      sampleNo: sampleTyped.sampleNo,
      sectionTypeId: sampleTyped.sectionTypeId,
      phyDimX: Number(sampleTyped.phyDimX),
      phyDimY: Number(sampleTyped.phyDimY),
      phyDimZ: Number(sampleTyped.phyDimZ),
      phyDimLengthUnitId: sampleTyped.phyDimLengthUnitId,
      stainingId: sampleTyped.stainingId,
      sectionNumber: Number(sampleTyped.sectionNumber),
      sectionOriginId: sampleTyped.sectionOriginId,
      orientationId: sampleTyped.orientationId,
    };

    return {
      [SAMPLE_TYPE_INFO_KEY_MAP[this.sampleType]]: new SAMPLE_TYPE_INFO_CLASS_MAP[this.sampleType](
        crossUnitedSampleInfo,
      ),
    };
  }

  private handleRouteParams(): void {
    this.isEdit = this.route.routeConfig?.path?.startsWith('edit') ?? false;

    this.route.paramMap.pipe(this.takeUntilDestroyed()).subscribe((params) => {
      this.resetForm();
      (this.sampleFormGroup as any) = null;

      const param = params.get('sampleType');
      this.sampleType = SampleType[
        param?.toUpperCase() as keyof typeof SampleType
      ] as DefaultSampleType;

      if (!this.sampleType) {
        this.router.navigate(['/']);
      } else {
        this.sampleId = params.get('sampleId') || undefined;
        this.parentSampleId = this.route.snapshot.queryParams['parentSampleId'];
        this.initComponentData();
      }
    });
  }

  private getFirstMessage(): string {
    const controls = {
      ...this.generalFormGroup.controls,
      ...this.typedFormGroup.controls,
      ...this.wellFormGroup.controls,
    };
    const controlWithError = Object.entries(controls).find(([, control]) => !!control.errors);
    const controlErrors = controlWithError?.[1].errors;

    if (controlErrors?.['required']) {
      return `${
        ALL_FORM_LABELS[controlWithError?.[0] as SampleCheckInFormField]
      } should not be empty.`;
    }
    if (controlErrors?.[allowedParentSampleErrorKey]) {
      return controlErrors[allowedParentSampleErrorKey];
    }
    if (controlErrors?.['maxlength']) {
      return `The ${
        ALL_FORM_LABELS[controlWithError?.[0] as SampleCheckInFormField]
      } input should contain less than ${
        controlErrors?.['maxlength'].requiredLength
      } characters or equal`;
    }
    if (this.sampleTypedFormRef.depthValidationMessage?.hasError()) {
      return this.sampleTypedFormRef.depthValidationMessage?.getFirstMessage();
    }
    if (this.generalFormGroup.hasError(REQUIRED_FIELDS_ERROR_CODE)) {
      return this.generalFormGroup.getError(REQUIRED_FIELDS_ERROR_CODE);
    }
    if (controlErrors?.['pattern']) {
      return `${
        ALL_FORM_LABELS[controlWithError?.[0] as SampleCheckInFormField]
      } should be in the correct email format`;
    }

    return 'Unhandled validation error';
  }

  private initForms(sampleType: SampleType): void {
    this.initMainForm();

    switch (sampleType) {
      case SampleType.PLUG:
        this.initPlugFormControls();
        break;
      case SampleType.CORE:
        this.initCoreFormControls();
        break;
      case SampleType.CUTTINGS:
        this.initCuttingsFormControls();
        break;
      case SampleType.FLUID:
        this.initFluidFormControls();
        break;
      case SampleType.UNCATEGORIZED:
        this.initUncategorizedFormControls();
        break;
      case SampleType.THINSECTION:
        this.initThinSectionFormControls();
        break;

      default:
        this.initPlugFormControls();
        break;
    }

    this.cd.detectChanges();

    this.fillWellInfoFromWellId();
    this.fillWellInfoFromParentSample();

    if (this.sampleId) {
      this.fillSampleDetails();
    }

    if (this.parentSampleId) {
      this.parentSampleFormControl.setValue(this.parentSampleId);

      if (!this.sampleId) {
        this.getParenSampleForValidationPurpose();
      }
    }

    this.setupUpdatingDepthsRangeValidation();

    this.isFormInitialized$.next();
  }

  private setupUpdatingDepthsRangeValidation(): void {
    this.parentSampleFormControl.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => {
      this.parentSample.value =
        this.sampleGeneralFormRef.parentSampleFieldComponent?.selectedSample ?? null;
      this.typedFormGroup.validator!(this.typedFormGroup);
    });
  }

  private initLists(): Observable<
    [
      LocationTreeViewNode[],
      IdName[],
      IdName[],
      IdName[],
      IdName[],
      IdName[],
      IdName[],
      IdName[],
      IdName[],
      IdName[],
    ]
  > {
    return forkJoin([
      this.locationTreeService.getLocationNodes(),
      this.sampleService.getOrientations(),
      this.sampleService.getPreservationTypes(),
      this.sampleService.getRestrictionTypes(),
      this.unitAttributeService.getAll().pipe(map(({ lengthUnits }) => lengthUnits)),
      this.vendorNameService.getAll(),
      this.experimentTypeService.getAll(),
      this.sampleService.getStainings(),
      this.sampleService.getSectionOrigins(),
      this.sampleService.getSectionTypes(),
    ]);
  }

  private initMainForm(): void {
    if (this.sampleFormGroup) {
      return;
    }

    this.sampleFormGroup = new UntypedFormGroup({
      [SampleFormGroup.GENERAL]: new UntypedFormGroup(
        {
          [SampleCheckInFormField.NAME]: new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(128),
          ]),
          [SampleCheckInFormField.RESTRICTION]: new UntypedFormControl([
            this.noRestrictionValue.id,
          ]),
          [SampleCheckInFormField.BARCODE]: new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(128),
          ]),
          [SampleCheckInFormField.QRCODE]: new UntypedFormControl('', [
            Validators.required,
            Validators.maxLength(128),
          ]),
          [SampleCheckInFormField.DESCRIPTION]: new UntypedFormControl('', [
            Validators.maxLength(32),
          ]),
          [SampleCheckInFormField.SAMPLE_LOCATION]: new UntypedFormControl(''),
          [SampleCheckInFormField.PARENT_SAMPLE]: new UntypedFormControl(''),
          [SampleCheckInFormField.OWNER]: new UntypedFormControl(
            { value: '', disabled: this.isEdit },
            [Validators.required, Validators.pattern(EMAIL_REGEX)],
          ),
          [SampleCheckInFormField.CUSTODIAN]: new UntypedFormControl('', [
            Validators.pattern(EMAIL_REGEX),
          ]),
        },
        [
          requiredFields(
            [SampleCheckInFormField.BARCODE, SampleCheckInFormField.QRCODE],
            SAMPLE_CHECK_IN_FORM_LABEL,
          ),
          allowedParentSampleValidator(this.sampleType, this.parentSample),
        ],
      ),
      [SampleFormGroup.WELL]: new UntypedFormGroup({
        [WellInformationFormField.WELL_ID]: new UntypedFormControl(null, [
          this.sampleType === SampleType.UNCATEGORIZED
            ? Validators.nullValidator
            : Validators.required,
        ]),
        [WellInformationFormField.WELL_LOCATION]: new UntypedFormControl({
          value: '',
          disabled: true,
        }),
        [WellInformationFormField.FIELD]: new UntypedFormControl({ value: '', disabled: true }),
        [WellInformationFormField.RESERVOIR]: new UntypedFormControl({ value: '', disabled: true }),
      }),
    });
  }

  private initPlugFormControls(): void {
    this.sampleFormGroup.addControl(
      SampleFormGroup.TYPED,
      new UntypedFormGroup(
        {
          [PlugSampleInformationFormField.DEPTH]: new UntypedFormControl({
            [UnitInputField.UNIT_ID]: '',
            [UnitInputField.FIELD_VALUE]: '',
          }),
          [PlugSampleInformationFormField.ORIENTATION]: new UntypedFormControl('', []),
          [PlugSampleInformationFormField.PRESERVATION_TYPE]: new UntypedFormControl('', [
            Validators.required,
          ]),
        },
        {
          validators: [
            depthInRange(
              [PlugSampleInformationFormField.DEPTH],
              [PlugSampleInformationFormField.DEPTH],
              this.parentSample,
            ),
          ],
        },
      ),
    );
  }

  private initCoreFormControls(): void {
    this.sampleFormGroup.addControl(
      SampleFormGroup.TYPED,
      new UntypedFormGroup(
        {
          [CoreSampleInformationFormField.DEPTH_TOP]: new UntypedFormControl({
            [UnitInputField.UNIT_ID]: '',
            [UnitInputField.FIELD_VALUE]: '',
          }),
          [CoreSampleInformationFormField.DEPTH_BOTTOM]: new UntypedFormControl({
            [UnitInputField.UNIT_ID]: '',
            [UnitInputField.FIELD_VALUE]: '',
          }),
          [CoreSampleInformationFormField.PRESERVATION_TYPE]: new UntypedFormControl('', [
            Validators.required,
          ]),
        },
        {
          validators: [
            depthInRange(
              [CoreSampleInformationFormField.DEPTH_TOP],
              [CoreSampleInformationFormField.DEPTH_BOTTOM],
              this.parentSample,
            ),
          ],
        },
      ),
    );
  }

  private initCuttingsFormControls(): void {
    this.sampleFormGroup.addControl(
      SampleFormGroup.TYPED,
      new UntypedFormGroup(
        {
          [CuttingsSampleInformationFormField.DEPTH_TOP]: new UntypedFormControl({
            [UnitInputField.UNIT_ID]: '',
            [UnitInputField.FIELD_VALUE]: '',
          }),
          [CuttingsSampleInformationFormField.DEPTH_BOTTOM]: new UntypedFormControl({
            [UnitInputField.UNIT_ID]: '',
            [UnitInputField.FIELD_VALUE]: '',
          }),
          [CuttingsSampleInformationFormField.PRESERVATION_TYPE]: new UntypedFormControl('', [
            Validators.required,
          ]),
        },
        {
          validators: [
            depthInRange(
              [CuttingsSampleInformationFormField.DEPTH_TOP],
              [CuttingsSampleInformationFormField.DEPTH_BOTTOM],
              this.parentSample,
            ),
          ],
        },
      ),
    );
  }

  private initFluidFormControls(): void {
    this.sampleFormGroup.addControl(
      SampleFormGroup.TYPED,
      new UntypedFormGroup(
        {
          [FluidSampleInformationFormField.DEPTH]: new UntypedFormControl({
            [UnitInputField.UNIT_ID]: '',
            [UnitInputField.FIELD_VALUE]: '',
          }),
        },
        {
          validators: [
            depthInRange(
              [FluidSampleInformationFormField.DEPTH],
              [FluidSampleInformationFormField.DEPTH],
              this.parentSample,
            ),
          ],
        },
      ),
    );
  }

  private initUncategorizedFormControls(): void {
    this.sampleFormGroup.addControl(
      SampleFormGroup.TYPED,
      new UntypedFormGroup(
        {
          [UncategorizedSampleInformationFormField.DEPTH]: new UntypedFormControl({
            [UnitInputField.UNIT_ID]: '',
            [UnitInputField.FIELD_VALUE]: '',
          }),
        },
        {
          validators: [
            depthInRange(
              [UncategorizedSampleInformationFormField.DEPTH],
              [UncategorizedSampleInformationFormField.DEPTH],
              this.parentSample,
            ),
          ],
        },
      ),
    );
  }

  private initThinSectionFormControls(): void {
    this.sampleFormGroup.addControl(
      SampleFormGroup.TYPED,
      new UntypedFormGroup(
        {
          [ThinSectionSampleInformationFormField.SECTION_TYPE]: new UntypedFormControl(''),
          [ThinSectionSampleInformationFormField.DEPTH_TOP]: new UntypedFormControl({
            [UnitInputField.UNIT_ID]: '',
            [UnitInputField.FIELD_VALUE]: '',
          }),
          [ThinSectionSampleInformationFormField.SECTION_DIMENSION_X]: new UntypedFormControl('', [
            Validators.required,
          ]),
          [ThinSectionSampleInformationFormField.SECTION_DIMENSION_Y]: new UntypedFormControl('', [
            Validators.required,
          ]),
          [ThinSectionSampleInformationFormField.SECTION_DIMENSION_Z]: new UntypedFormControl('', [
            Validators.required,
          ]),
          [ThinSectionSampleInformationFormField.SECTION_DIMENSION_UNIT_ID]: new UntypedFormControl(
            this.lengthUnits[4].id,
          ),
          [ThinSectionSampleInformationFormField.DEPTH_BOTTOM]: new UntypedFormControl({
            [UnitInputField.UNIT_ID]: '',
            [UnitInputField.FIELD_VALUE]: '',
          }),
          [ThinSectionSampleInformationFormField.STAINING]: new UntypedFormControl(''),
          [ThinSectionSampleInformationFormField.SECTION_NUMBER]: new UntypedFormControl('', [
            Validators.required,
            integerOnly,
          ]),
          [ThinSectionSampleInformationFormField.ORIENTATION]: new UntypedFormControl(''),
          [ThinSectionSampleInformationFormField.SECTION_ORIGIN]: new UntypedFormControl(''),
        },
        {
          validators: [
            depthInRange(
              [ThinSectionSampleInformationFormField.DEPTH_TOP],
              [ThinSectionSampleInformationFormField.DEPTH_BOTTOM],
              this.parentSample,
            ),
          ],
        },
      ),
    );
  }

  private fillWellInfoFromWellId(): void {
    const wellChanges$ = this.wellIdFormControl.valueChanges.pipe(
      distinctUntilChanged(),
      this.takeUntilDestroyed(),
    );

    wellChanges$
      .pipe(
        filter((wellId) => !!wellId),
        switchMap((wellId) => this.wellsService.getById(wellId)),
        map(this.toWellFormValues),
      )
      .subscribe((wellFormValues) => {
        this.sampleFormGroup.patchValue(wellFormValues, { emitEvent: false });
        this.wellFormRef.markForCheck();
      });

    wellChanges$.pipe(filter((wellId) => !wellId)).subscribe((_) => {
      const emptyWell = this.toWellFormValues({} as Well);
      this.sampleFormGroup.patchValue(emptyWell, { emitEvent: false });
      this.wellFormRef.markForCheck();
    });
  }

  private fillWellInfoFromParentSample(): void {
    const parentChanges$ = this.parentSampleFormControl.valueChanges.pipe(
      distinctUntilChanged(),
      this.takeUntilDestroyed(),
    );

    parentChanges$
      .pipe(
        filter((parentId) => !!parentId),
        switchMap((parentId) => this.sampleService.getById(parentId)),
      )
      .subscribe((parent) => {
        const wellFormValues = this.toWellFormValues({
          ...(parent.wellInformation ?? {}),
        } as WellInformation);
        this.sampleFormGroup.patchValue(
          {
            [SampleFormGroup.WELL]: {
              ...wellFormValues[SampleFormGroup.WELL],
              [WellInformationFormField.WELL_ID]: parent.wellInformation?.id,
            },
          },
          { emitEvent: false },
        );

        this.wellIdFormControl.disable({ emitEvent: false });
        this.wellFormRef.markForCheck();
      });

    parentChanges$.pipe(filter((parentId) => !parentId)).subscribe((_) => {
      if (this.wellIdFormControl.disabled) {
        this.wellIdFormControl.enable({ emitEvent: false });
      }
    });
  }

  private fillSampleDetails(): void {
    this.sampleService
      .getById(this.sampleId!)
      .pipe(
        switchMap((sample) => {
          return sample.parentSampleId
            ? this.sampleService.getById(sample.parentSampleId).pipe(
                map((parentSample) =>
                  this.mapToSampleIdDisplayName(sample.parentSampleId, parentSample),
                ),
                map((parentSample) => ({ sample, parentSample })),
              )
            : of({ sample, parentSample: null });
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe(({ sample, parentSample }) => {
        this.sampleFormGroup.patchValue(this.toFormValues(sample), { emitEvent: false });
        this.parentSample.value = parentSample;
        this.sampleImageId = sample.sampleImageId;
        this.sampleImageOriginId = sample.sampleImageOriginId;

        if (sample.sampleImageId) {
          this.loadSampleImage(sample);
        }

        if (!!sample.parentSampleId && this.wellIdFormControl.enabled) {
          this.wellIdFormControl.disable({ emitEvent: false });
        }

        this.relatedSamples = sample.relatedSamples;
        this.sampleAccess = sample.sampleAccesses.map((sa) => {
          // HACK: temporary fix to exclude nonsense string
          if (sa.grantDate.toLowerCase() === 'z') {
            sa.grantDate = '';
          }
          return sa;
        });
        this.isOwner = sample.owner === this.currentUser;
      });
  }

  private loadSampleImage(sample: SampleGetById): void {
    this.attachedFileService
      .downloadImage(sample.sampleImageId)
      .pipe(
        switchMap((blob: Blob) => this.dataUrlBlobCodecService.blobToDataURL(blob)),
        this.takeUntilDestroyed(),
      )
      .subscribe((sampleImageUrl) => {
        this.picture.src = sampleImageUrl;
        this.sampleGeneralFormRef.detectChanges();
      });
  }

  private toWellFormValues(well: WellInformation | Well): { [key: string]: any } {
    return {
      [SampleFormGroup.WELL]: {
        [WellInformationFormField.WELL_LOCATION]: well.location,
        [WellInformationFormField.FIELD]: well.field,
        [WellInformationFormField.RESERVOIR]: well.reservoir,
      },
    };
  }

  private toFormValues(sample: SampleGetById): { [key: string]: any } {
    const formValues = {
      [SampleFormGroup.GENERAL]: {
        [SampleCheckInFormField.NAME]: sample.name,
        [SampleCheckInFormField.SAMPLE_LOCATION]: sample.locationId,
        [SampleCheckInFormField.PARENT_SAMPLE]: sample.parentSampleId,
        [SampleCheckInFormField.RESTRICTION]: sample.sampleLimitationIds.length
          ? sample.sampleLimitationIds
          : '',
        [SampleCheckInFormField.QRCODE]: sample.qrCodeId,
        [SampleCheckInFormField.BARCODE]: sample.barcodeId,
        [SampleCheckInFormField.DESCRIPTION]: sample.description,
        [SampleCheckInFormField.OWNER]: sample.owner,
        [SampleCheckInFormField.CUSTODIAN]: sample.custodian,
      },
      [SampleFormGroup.WELL]: {
        [WellInformationFormField.WELL_ID]: sample.wellInformation?.id,
        [WellInformationFormField.WELL_LOCATION]: sample.wellInformation?.location,
        [WellInformationFormField.FIELD]: sample.wellInformation?.field,
        [WellInformationFormField.RESERVOIR]: sample.wellInformation?.reservoir,
      },
    };

    let result: { [key: string]: any };

    switch (this.sampleType) {
      case SampleType.PLUG:
        result = {
          ...formValues,
          [SampleFormGroup.TYPED]: {
            [PlugSampleInformationFormField.DEPTH]: {
              [UnitInputField.UNIT_ID]: sample.plugInformation!.depthUnitId,
              [UnitInputField.FIELD_VALUE]: sample.plugInformation!.depth,
            },
            [PlugSampleInformationFormField.ORIENTATION]: sample.plugInformation!.orientationId,
            [PlugSampleInformationFormField.PRESERVATION_TYPE]:
              sample.plugInformation!.preservationId,
          },
        };
        break;

      case SampleType.CORE:
        result = {
          ...formValues,
          [SampleFormGroup.TYPED]: {
            [CoreSampleInformationFormField.DEPTH_TOP]: {
              [UnitInputField.UNIT_ID]: sample.coreInformation!.depthUnitId,
              [UnitInputField.FIELD_VALUE]: sample.coreInformation!.depthTop,
            },
            [CoreSampleInformationFormField.DEPTH_BOTTOM]: {
              [UnitInputField.UNIT_ID]: sample.coreInformation!.depthUnitId,
              [UnitInputField.FIELD_VALUE]: sample.coreInformation!.depthBottom,
            },
            [CoreSampleInformationFormField.PRESERVATION_TYPE]:
              sample.coreInformation!.preservationId,
          },
        };
        break;

      case SampleType.CUTTINGS:
        result = {
          ...formValues,
          [SampleFormGroup.TYPED]: {
            [CuttingsSampleInformationFormField.DEPTH_TOP]: {
              [UnitInputField.UNIT_ID]: sample.cuttingsIntervalInformation!.depthUnitId,
              [UnitInputField.FIELD_VALUE]: sample.cuttingsIntervalInformation!.depthTop,
            },
            [CuttingsSampleInformationFormField.DEPTH_BOTTOM]: {
              [UnitInputField.UNIT_ID]: sample.cuttingsIntervalInformation!.depthUnitId,
              [UnitInputField.FIELD_VALUE]: sample.cuttingsIntervalInformation!.depthBottom,
            },
            [CuttingsSampleInformationFormField.PRESERVATION_TYPE]:
              sample.cuttingsIntervalInformation!.preservationId,
          },
        };
        break;

      case SampleType.FLUID:
        result = {
          ...formValues,
          [SampleFormGroup.TYPED]: {
            [FluidSampleInformationFormField.DEPTH]: {
              [UnitInputField.UNIT_ID]: sample.fluidInformation!.depthUnitId,
              [UnitInputField.FIELD_VALUE]: sample.fluidInformation!.depth,
            },
          },
        };
        break;
      case SampleType.UNCATEGORIZED:
        result = {
          ...formValues,
          [SampleFormGroup.TYPED]: {
            [UncategorizedSampleInformationFormField.DEPTH]: {
              [UnitInputField.UNIT_ID]: sample.uncategorizedInformation!.depthUnitId,
              [UnitInputField.FIELD_VALUE]: sample.uncategorizedInformation!.depth,
            },
          },
        };
        break;

      case SampleType.THINSECTION:
        result = {
          ...formValues,
          [SampleFormGroup.TYPED]: {
            [ThinSectionSampleInformationFormField.DEPTH_TOP]: {
              [UnitInputField.UNIT_ID]: sample.thinSectionInformation!.depthUnitId,
              [UnitInputField.FIELD_VALUE]: sample.thinSectionInformation!.depthTop,
            },
            [ThinSectionSampleInformationFormField.DEPTH_BOTTOM]: {
              [UnitInputField.UNIT_ID]: sample.thinSectionInformation!.depthUnitId,
              [UnitInputField.FIELD_VALUE]: sample.thinSectionInformation!.depthBottom,
            },
            [ThinSectionSampleInformationFormField.ORIENTATION]:
              sample.thinSectionInformation!.orientationId,
            [ThinSectionSampleInformationFormField.SECTION_DIMENSION_UNIT_ID]:
              sample.thinSectionInformation!.phyDimLengthUnitId,
            [ThinSectionSampleInformationFormField.SECTION_DIMENSION_X]:
              sample.thinSectionInformation!.phyDimX,
            [ThinSectionSampleInformationFormField.SECTION_DIMENSION_Y]:
              sample.thinSectionInformation!.phyDimY,
            [ThinSectionSampleInformationFormField.SECTION_DIMENSION_Z]:
              sample.thinSectionInformation!.phyDimZ,
            [ThinSectionSampleInformationFormField.SECTION_NUMBER]:
              sample.thinSectionInformation!.sectionNumber,
            [ThinSectionSampleInformationFormField.SECTION_ORIGIN]:
              sample.thinSectionInformation!.sectionOriginId,
            [ThinSectionSampleInformationFormField.SECTION_TYPE]:
              sample.thinSectionInformation!.sectionTypeId,
            [ThinSectionSampleInformationFormField.STAINING]:
              sample.thinSectionInformation!.stainingId,
          },
        };
        break;

      default:
        result = formValues;
    }

    return result;
  }

  private getDefaultNamingFormula(sample: SampleSaveParticular): string {
    const selectedWellName = this.wellFormRef?.wellTableSelectorRef?.selectedWell?.name as string;
    const sampleIfoKey = SAMPLE_TYPE_INFO_KEY_MAP[this.sampleType];

    const depthString =
      this.sampleType === SampleType.CORE ||
      this.sampleType === SampleType.CUTTINGS ||
      this.sampleType === SampleType.THINSECTION
        ? // @ts-ignore
          `${sample[sampleIfoKey].depthTop}-${sample[sampleIfoKey].depthBottom}`
        : // @ts-ignore
          `${sample[sampleIfoKey].depth}`;

    return `${sample.name}-${this.sampleType}-${depthString}-${selectedWellName}`;
  }

  private checkInSample(
    sampleSaveParticular: SampleSaveParticular,
    sampleImage: File | null,
    sampleFullName: string,
    sampleActionsComponent: { savingFinished(): void },
  ): void {
    const createSampleObserver: Partial<Observer<SampleSaveParticular>> = {
      next: (_) => {
        this.notificationService.notifySuccess(
          `Sample ${sampleFullName} has been successfully created`,
        );
        sampleActionsComponent.savingFinished();
        this.resetForm();
        this.clearRouteParams();
      },
      error: () => {
        sampleActionsComponent.savingFinished();
        this.cd.markForCheck();
      },
    };

    if (sampleImage) {
      this.attachedFileService
        .uploadSampleImage(sampleImage)
        .pipe(
          switchMap(({ sampleImageId, sampleImageOriginId }) => {
            return this.sampleService.create(
              { ...sampleSaveParticular, sampleImageId, sampleImageOriginId },
              this.sampleType,
            );
          }),
          this.takeUntilDestroyed(),
        )
        .subscribe(createSampleObserver);

      return;
    }

    this.sampleService
      .create(sampleSaveParticular, this.sampleType)
      .pipe(this.takeUntilDestroyed())
      .subscribe(createSampleObserver);
  }

  private updateSample(
    sampleSaveParticular: SampleSaveParticular,
    sampleImage: File | null,
    sampleFullName: string,
    sampleActionsComponent: { savingFinished(): void },
  ): void {
    const updateSampleObserver: Partial<Observer<null>> = {
      next: (_) => {
        this.notificationService.notifySuccess(
          `Sample ${sampleFullName} has been successfully updated`,
        );
        sampleActionsComponent.savingFinished();
        this.navigateToSampleLookup();
      },
      error: () => {
        sampleActionsComponent.savingFinished();
        this.cd.markForCheck();
      },
    };

    if (sampleImage && this.sampleGeneralFormRef.isSamplePhotoChanged) {
      this.attachedFileService
        .uploadSampleImage(sampleImage)
        .pipe(
          switchMap(({ sampleImageId, sampleImageOriginId }) => {
            return this.sampleService.update(this.sampleId!, this.sampleType, {
              ...sampleSaveParticular,
              sampleImageId,
              sampleImageOriginId,
            });
          }),
          this.takeUntilDestroyed(),
        )
        .subscribe(updateSampleObserver);

      return;
    }

    this.sampleService
      .update(this.sampleId!, this.sampleType, sampleSaveParticular)
      .pipe(this.takeUntilDestroyed())
      .subscribe(updateSampleObserver);
  }

  private updateRouteParams(): void {
    if (!this.isEdit) {
      this.isFormInitialized$
        .pipe(
          switchMap(() => this.parentSampleFormControl.valueChanges),
          distinctUntilChanged(),
          this.takeUntilDestroyed(),
        )
        .subscribe((value) => {
          this.router.navigate([], { queryParams: { parentSampleId: value } });
        });
    }
  }

  private getAdditionalInfo(): void {
    if (!this.sampleId) {
      return;
    }

    forkJoin([this.getExistingWorkOrders(), this.experimentDataService.getAllBy(this.sampleId)])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([existingWorkOrders, experimentDataList]) => {
        this.existingWorkOrders = existingWorkOrders;
        this.experimentDataList = experimentDataList;
        this.filterEmptyTable(experimentDataList);
        this.cd.markForCheck();
      });
  }

  private filterEmptyTable(experimentDataList: ExperimentData[]): void {
    this.experimentDataList = experimentDataList.filter((expData) => {
      return expData.tables[0].body.length;
    });
  }

  private getExistingWorkOrders(): Observable<WorkOrderFormShapeImpl[]> {
    return this.workOrderService.getAllBy(this.sampleId!).pipe(
      map((workOrders) => workOrders.map((workOrder) => new WorkOrderFormShapeImpl(workOrder))),
      map((workOrders) =>
        workOrders.map((workOrder) => {
          const experimentTypeId = workOrder.experimentDetail.experimentType;

          if (!experimentTypeId) {
            return workOrder;
          }

          const experimentType = this.experimentTypes.find(
            (expType) => expType.id === experimentTypeId,
          )!;

          workOrder.experimentDetail['experimentName'] = experimentType.name;

          const isNewExperimentType = Object.values(NewExperimentType).includes(
            experimentType.name as NewExperimentType,
          );

          if (isNewExperimentType) {
            return {
              ...workOrder,
              experimentDetail: {
                ...workOrder.experimentDetail,
                experimentFields: [...newExperimentTypeFields],
              },
            };
          }

          return workOrder;
        }),
      ),
      this.takeUntilDestroyed(),
    );
  }

  @throttleClick()
  save(throttleClickDirective: ThrottleClickDirective, component: WorkOrderFormComponent): void {
    const validationState = this.checkIfFormIsValid(component);

    if (!validationState.isValid) {
      component.workOrderFormGroup.markAllAsTouched();
      component.componentsWithInnerFormControls.forEach((cmp) => cmp.detectChanges());
      this.notificationService.notifyError(validationState.errorMessage);
      throttleClickDirective.isNextClickAllowed$.next(true);
      return;
    }

    const experimentFields = this.getExperimentFields(component);
    const payload = new WorkOrderImpl({
      ...component.workOrderFormGroup.getRawValue(),
      sampleId: this.sampleId,
      experimentFields,
    });

    this.workOrderService
      .update(payload)
      .pipe(throttleClickDirective.allowNextClickAfterFinalized(), this.takeUntilDestroyed())
      .subscribe(() => {
        this.notificationService.notifySuccess('Work order updated');
        this.cd.markForCheck();
        component.detectChanges();
      });
  }

  private checkIfFormIsValid(component: WorkOrderFormComponent): ValidationState {
    if (component.workOrderFormGroup.status === 'VALID') {
      return { isValid: true };
    }

    const validationMessage = component.dynamicValidationMessageList.find((valMessage) =>
      valMessage.hasError(),
    )!;

    return {
      isValid: false,
      errorMessage: validationMessage.getFirstMessage(),
    };
  }

  private getExperimentFields(component: WorkOrderFormComponent): ExperimentField[] {
    const { experimentFieldsMap, dynamicFieldFormGroup } =
      component.dynamicExperimentTemplateComponent;

    const experimentFields = Object.values(experimentFieldsMap).map((experimentField) => {
      const fieldValue = dynamicFieldFormGroup.value[experimentField.fieldName];

      if (fieldValue === null || typeof fieldValue === 'string' || typeof fieldValue === 'number') {
        return { ...experimentField, fieldValue };
      }

      return {
        ...experimentField,
        fieldValue: fieldValue[UnitInputField.FIELD_VALUE],
        unitValue: fieldValue[UnitInputField.UNIT_ID],
      };
    });

    return experimentFields;
  }
}

@NgModule({
  declarations: [SampleComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    ReactiveFormsModule,
    SampleActionsModule,
    SampleGeneralFormModule,
    SampleHeaderModule,
    SampleRelatedTableModule,
    SampleTypedFormModule,
    SampleWellFormModule,
    SampleAccessTableModule,
    ExistingWorkOrdersModule,
    AttachedFilesModule,
    ExperimentDataRenderTableModule,
  ],
  exports: [SampleComponent],
  providers: [
    SampleService,
    WorkOrderService,
    VendorNameService,
    ExperimentTypeService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
  ],
})
export class SampleModule {}
