import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { DefaultSampleType } from '@core/types/default-sample-type.type';
import { addError } from '@core/utils/form/add-error.util';
import { removeError } from '@core/utils/form/remove-error.util';

import { SampleType } from '../../../common/sample-check-in-menu/enums/sample-type.enum';
import { SampleIdDisplayName } from '../../../common/sample-table-selector-popup-field/models/sample-display-name.interface';
import { SampleCheckInFormField } from './sample-check-in-form-field.enum';

export const allowedParentSampleErrorKey = 'allowedParentSampleErrorKey';

export function allowedParentSampleValidator(
  sampleType: DefaultSampleType,
  parentSample: {
    value: SampleIdDisplayName | null;
  },
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const parentSampleControl = control.get(SampleCheckInFormField.PARENT_SAMPLE)!;
    const isErrorApplied = !!parentSampleControl.errors?.[allowedParentSampleErrorKey];

    let isError = false;
    let errorMessage = '';

    if (parentSample.value) {
      if (sampleType === SampleType.UNCATEGORIZED) {
        if (parentSample.value.type !== SampleType.UNCATEGORIZED) {
          isError = true;
          errorMessage = 'Uncategorized Sample can only have Uncategorized Sample parent';
        }
      } else if (parentSample.value.type === SampleType.UNCATEGORIZED) {
        isError = true;
        errorMessage = 'Uncategorized Sample can only have Uncategorized Sample children';
      } else if (parentSample.value.type === SampleType.COMPOSITE) {
        isError = true;
        errorMessage = 'Composite Sample can not have children';
      }
    }

    if (isError && !isErrorApplied) {
      addError(parentSampleControl, allowedParentSampleErrorKey, errorMessage);

      return null;
    }

    if (!isError && isErrorApplied) {
      removeError(parentSampleControl, allowedParentSampleErrorKey);
    }

    return null;
  };
}
